.footer {
	position: relative;
	height: 0;
	.col-wrap {
		z-index: 2;
		height: 325px;
	}
	
	.logo {
		img {
			display: block;
		}
	}

	h2{
		padding-top: 112px;
		font-size:34px;
		font-family:Alibaba PuHuiTi;
		font-weight:500;
		color:#333655;
		line-height:50px;
		padding-bottom: 36px;
	}
	h3{
		font-size:16px;
		font-family:Source Han Sans CN;
		font-weight:400;
		color:#626475
	}

	.crp {
		position: absolute;
		top: 82px;
		right: 0;
		line-height: 1;
		text-align: center;
		font-size: 16px;
		color: #637178;
	}
	.copyright{
		text-align: center;
		margin-bottom: 10px;
	}
}
